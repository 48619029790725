import { redirectTo } from './fns/redirectTo';
import { CognitoUser } from '@aws-amplify/auth';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  setupInterceptors,
  login,
  logout,
  changePassword,
  getUser,
  requestResetPassword,
  exceptionMapper,
  IAmplifyException,
  resetPassword,
  getAttributes,
} from './fns';
import { awsExports, ERROR_USER_NOT_LOGIN, PATH } from '@configs';
import { Amplify } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import type {
  IChangePasswordFn,
  ICognitoUser2,
  IGetAttributeFn,
  IRequestResetPasswordFn,
  IResetPasswordFn,
  IUserAttribute,
} from '@types';
import Cookies from 'js-cookie';
import { COOKIE_NAME } from './constants';
import { removeCustomCookie, setCustomCookie, getToken } from './fns/utils';
import axios, { AxiosError } from 'axios';

export type IUseAuthProvider = {
  redirectUrl: string;
};

export type IUserAuth = {
  tempCognitoRequestEmail: string;
  tempCognitoUser: ICognitoUser2 | null;
  cognitoUser: ICognitoUser2 | null;
  errorMessage: string;
  loading: boolean;
  cognitoAuthLoading: boolean;
  resettingPassword: boolean;
  onLogin: typeof login;
  onLogout: typeof logout;
  onChangePassword: IChangePasswordFn;
  onRequestResetPassword: IRequestResetPasswordFn;
  onResetPassword: IResetPasswordFn;
  onGetAttributes: IGetAttributeFn;
  onClearErrorMessage: () => void;
  onClearCognitoUser: () => void;
};

export const useAuthProvider = ({ redirectUrl }: IUseAuthProvider) => {
  const tempCognitoRequestEmail = useRef<IUserAuth['tempCognitoRequestEmail']>('');
  const tempCognitoUser = useRef<IUserAuth['tempCognitoUser']>(null);
  const tempPasswordRef = useRef<string>('');
  const [cognitoUser, setCognitoUser] = useState<IUserAuth['cognitoUser']>(null);
  const [loading, setLoading] = useState(false);
  const [resettingPassword, setResettingPassword] = useState(false);
  const [cognitoAuthLoading, setCognitoAuthLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<IUserAuth['errorMessage']>('');
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    try {
      //initial Amplify
      Amplify.configure(awsExports);
      tryLogin();
    } catch (error) {
      console.log(error);
    }
    setupInterceptors(onAuthError);
  }, []);

  const onAuthError = () => {
    navigate(redirectUrl);
  };



  const tryLogin = useCallback(async () => {
    try {
      const userData = await getUser();
      setCognitoUser(userData);
      setCognitoAuthLoading(false);
      const pathWithQuery = pathname + search;
      navigate(redirectTo(pathWithQuery));
    } catch (error) {
      console.error(error);
      setCognitoAuthLoading(false);
      setCognitoUser(null);
      onAnyProcessError(error);
    }
  }, []);



  const onClearErrorMessage = () => {
    setErrorMessage('');
  };

  const onAnyProcessError = (error: IAmplifyException | any) => {
    setLoading(false);
    if (error === 'The user is not authenticated') {
      return;
    }
    setErrorMessage(exceptionMapper(error as IAmplifyException));
  };

  return {
    tempCognitoRequestEmail: tempCognitoRequestEmail.current,
    tempCognitoUser: tempCognitoUser.current,
    cognitoUser,
    loading,
    resettingPassword,
    cognitoAuthLoading,
    errorMessage,
    onClearErrorMessage,
    onLogin: async (username: string, password: string) => {
      try {
        setLoading(true);
        // set temp password
        tempPasswordRef.current = password;
        const result = await login(username, password);
        onClearErrorMessage();
        const params = new URL(window.location.href).searchParams;
        const redirectQuery = params.get('redirect');
        if (result?.challengeName === 'NEW_PASSWORD_REQUIRED') {
          tempCognitoUser.current = result;
          setLoading(false);
          if (redirectQuery) {
            return navigate(`${PATH.CHANGE_PASSWORD}?redirect=${redirectQuery}`);
          }
          return navigate(PATH.CHANGE_PASSWORD);
        }
        //set custom cookie
        const token = await getToken();
        setCustomCookie(COOKIE_NAME.MEGABOT_TOKEN, token);
        //check redirectUrl if user redirect from chatlogic
        const redirectUrl = Cookies.get(COOKIE_NAME.REDIRECT_URL);
        // redirect to chatlogic
        if (redirectUrl) {
          //add token to cookie
          removeCustomCookie(COOKIE_NAME.REDIRECT_URL);
          window.location.href = redirectUrl;
          return;
        }
        setLoading(false);
        setCognitoUser(result);
        // redirect query

        if (redirectQuery) {
          if (redirectQuery.startsWith('http')) {
            window.location.href = redirectQuery;
          } else {
            navigate(redirectQuery, { replace: true });
          }

          return;
        }

        navigate('/');
      } catch (error) {
        setLoading(false);
        const castError = error as IAmplifyException;
        // clear temp password
        tempPasswordRef.current = '';
        if (castError.message === 'Password reset required for the user') {
          // Admin force reset password from Cognito console
          tempCognitoRequestEmail.current = username;
          setErrorMessage('Password reset required for the user');
          return navigate(PATH.RESET_PASSWORD);
        }
        onAnyProcessError(error);
      }
    },
    onClearCognitoUser: () => {
      setCognitoUser(null);
    },
    onLogout: logout,
    onChangePassword: async ({ password }: { password: string }) => {
      if (!tempCognitoUser.current) throw new Error(ERROR_USER_NOT_LOGIN);
      try {
        setLoading(true);
        await changePassword(tempCognitoUser.current, password, tempPasswordRef.current);
        await login(tempCognitoUser.current.getUsername(), password);
        setCognitoUser(tempCognitoUser.current);
        setLoading(false);
        onClearErrorMessage();
        const params = new URL(window.location.href).searchParams;
        const redirectQuery = params.get('redirect');
        if (redirectQuery) {
          if (redirectQuery.startsWith('http')) {
            window.location.href = redirectQuery;
          } else {
            navigate(redirectQuery, { replace: true });
          }

          return;
        }
        navigate('/');
      } catch (err: unknown | AxiosError) {
        if (axios.isAxiosError(err)) {
          onAnyProcessError(err.response?.data);
        } else {
          onAnyProcessError(err);
        }
        setLoading(false);
      }
    },
    onRequestResetPassword: async ({ email }: { email: string }) => {
      try {
        setLoading(true);
        tempCognitoRequestEmail.current = email;
        await requestResetPassword(email);
        onClearErrorMessage();
        setLoading(false);
        navigate(PATH.RESET_PASSWORD);
      } catch (error) {
        onAnyProcessError(error);
      }
    },
    onResetPassword: async ({
      code,
      newPassword,
    }: {
      code: string;
      newPassword: string;
    }) => {
      if (!tempCognitoRequestEmail.current) {
        setErrorMessage(
          'Email is required please go back to previous page and try again',
        );
        return;
      }
      try {
        setResettingPassword(true);
        await resetPassword(tempCognitoRequestEmail.current, code, newPassword);
        setResettingPassword(false);
        onClearErrorMessage();
        navigate('/');
      } catch (err: unknown | AxiosError) {
        if (axios.isAxiosError(err)) {
          onAnyProcessError(err.response?.data);
        } else {
          onAnyProcessError(err);
        }

        setResettingPassword(false);
      }
    },
    onGetAttributes: async (
      cognitoUser: CognitoUser,
    ): Promise<IUserAttribute[] | undefined> => {
      if (!cognitoUser) throw new Error(ERROR_USER_NOT_LOGIN);
      try {
        setLoading(true);
        const result = await getAttributes(cognitoUser);
        setLoading(false);
        return result;
      } catch (error) {
        console.log(error);
      }
    },
  };
};
