import styled from 'styled-components/macro';
import { Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VALIDATE_TEXT_COLOR } from '@configs';
import { Button, ConfirmModal, TertiaryButton } from '@amityco/diana-bot';

export const Font10G4W600 = styled.span`
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  color: ${({ theme }) => theme.palette?.GREY_4};
`;

export const Font10G5W600 = styled.span`
  font-size: 10px;
  font-weight: 600;
  line-height: 12px;
  color: ${({ theme }) => theme.palette?.GREY_5};
`;

export const Font12PrimaryW500 = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.palette?.PRIMARY};
  line-height: 16px;
  font-weight: 500;
`;

export const Font12DangerW500 = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.palette?.RED};
  line-height: 16px;
  font-weight: 500;
`;

export const Font12WhiteW400 = styled.span`
  color: ${({ theme }) => theme.palette?.WHITE};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin: 0;
`;

export const Font12WhiteW500 = styled.span`
  color: ${({ theme }) => theme.palette?.WHITE};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin: 0;
`;

export const Font12G1W400 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_1};
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  letter-spacing: -0.1px;
`;

export const Font12G1 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_1};
  font-size: 12px;
  font-weight: 500;
  margin: 0;
`;

export const Font12G3W400 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_3};
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  line-height: 16px;
`;

export const Font12G3W500 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_3};
  font-size: 12px;
  font-weight: 500;
  margin: 0;
  line-height: 16px;
`;

export const Font12G4W400 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_4};
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  line-height: 16px;
`;

export const Font12 = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette?.GREY_5};
  margin: 0;
`;

export const Font12G5W500 = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: ${({ theme }) => theme.palette?.GREY_5};
  margin: 0;
`;

export const Font12G5W400 = styled.span`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.palette?.GREY_5};
  white-space: pre-line;
`;

export const Font12G1W600 = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette?.GREY_1};
  margin: 0;
`;

export const Font14 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_5};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
`;

export const Font14G1W400 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_1};
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
`;

export const Font14G1W500 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_1};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

export const Font14G1W600 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_1};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

export const Font14WhiteW600 = styled.span`
  color: ${({ theme }) => theme.palette?.WHITE};
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

export const Font14G3W600 = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.palette?.GREY_3};
`;

export const Font14G3W400 = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.palette?.GREY_3};
  line-height: 20px;
`;
export const Font14G3W400PreWrap = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.palette?.GREY_3};
  line-height: 20px;
  white-space: pre-wrap;
`;
export const Font14G3W500 = styled.span`
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.palette?.GREY_3};
  line-height: 20px;
`;

export const Font14G5W400 = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette?.GREY_5};
  margin: 0;
  white-space: pre-line;
`;

export const Font14G5W500 = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette?.GREY_5};
  margin: 0;
  line-height: 20px;
`;

export const Font14C084500 = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.2px;
  color: rgba(0, 0, 0, 0.18);
  white-space: pre-line;
  margin: 0;
`;

export const Font14PrimaryW400 = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette?.PRIMARY};
  line-height: 20px;
`;
export const Font14PrimaryW500 = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette?.PRIMARY};
  line-height: 20px;
`;

export const Font16G1 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_1};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
`;

export const Font16G1W400 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_1};
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  white-space: pre-line;
`;

export const Font16G1W400PreLine = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_1};
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  white-space: pre-line;
`;

export const Font16G3 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_3};
  font-size: 16px;
  font-weight: 500;
  margin: 0;
`;

export const Font16DisabledW400 = styled.span`
  color: rgba(0, 0, 0, 0.18);
  font-size: 16px;
  font-weight: 400;
  margin: 0;
`;

export const Font16PRIMARYW500 = styled.span`
  color: ${({ theme }) => theme.palette?.PRIMARY};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
`;

export const Font16PrimaryW400 = styled.span`
  color: ${({ theme }) => theme.palette?.PRIMARY};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
`;

export const Font16G1W500 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_1};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
`;

export const Font16G1W600 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_1};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
`;

export const Font16G3W400 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_3};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
`;

export const Font16G3W500 = styled.p`
  color: ${({ theme }) => theme.palette?.GREY_3};
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  white-space: pre-line;
  line-height: 24px;
`;

export const Font16G3W600 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_3};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
`;

export const Font16G5W400 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_5};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
`;

export const Font16PlaceHolder = styled.span`
  color: #9e9e9e;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
`;

export const Font20G1W600 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_1};
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
`;

export const Font20G5W600 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_5};
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
`;

export const Font20G3W600 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_3};
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
`;

export const Font24G1W500 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_1};
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
`;

export const Font24G1W600 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_1};
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
`;

export const Font24G1W700 = styled.span`
  color: ${({ theme }) => theme.palette?.GREY_1};
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`;

export const Font32G1W600 = styled.span`
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  color: ${({ theme }) => theme.palette?.GREY_1};
`;

export const Font40G1W700 = styled.span`
  font-size: 40px;
  font-weight: 700;
  line-height: 36px;
  color: ${({ theme }) => theme.palette?.GREY_1};
  letter-spacing: -0.4px;
`;

export const Font40G1W500 = styled.span`
  font-size: 40px;
  font-weight: 500;
  line-height: 36px;
  color: ${({ theme }) => theme.palette?.GREY_1};
  letter-spacing: -0.4px;
  white-space: pre-line;
`;

export const PrimaryLink = styled.a`
  color: ${({ theme }) => theme.palette?.PRIMARY};
  cursor: pointer;
`;

export const CursorPointerWrap = styled.span`
  cursor: pointer;
`;

export const ButtonDisabled = styled.button<{ isDisabled: boolean }>`
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  background: transparent;
`;

export const ValidateMessage = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.palette?.RED};
`;

export const FontHeader = styled(Typography.Title)`
  margin: 0 !important;
`;

export const GreyIcon = styled(FontAwesomeIcon)`
  font-size: 36px;
  font-weight: 400;
  color: ${({ theme }) => theme.palette?.GREY_6};
`;

export const BlackIcon = styled(FontAwesomeIcon)`
  font-weight: 900;
  font-size: 16px;
  color: ${({ theme }) => theme.palette?.GREY_4};
`;

export const Label = styled.label`
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.2px;
  color: ${({ theme }) => theme.palette?.GREY_1};
`;

export const InputContainer = styled.div`
  display: grid;
  row-gap: 8px;
`;

export const IconBtn18 = styled(FontAwesomeIcon)`
  font-size: 18px;
  margin-right: 8px;
`;

export const IconBtn12 = styled(FontAwesomeIcon)`
  font-size: 12px;
  margin-right: 8px;
`;

export const TrashIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette?.RED};
`;

export const MediaIconLarge = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette?.GREY_6};
  font-size: 72px;
  margin-bottom: 18px;
`;

export const MediaIconSmall = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette?.GREY_6};
  font-size: 36px;
  margin-bottom: 18px;
`;

export const RedNote = styled.span`
  color: ${({ theme }) => theme.palette?.RED};
  display: inline !important;
`;

export const HelperText = styled.span`
  margin-top: 4px;
  margin-bottom: 0px !important;
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.palette?.GREY_5};
  display: block;
`;

export const HelperTextRed = styled.span`
  margin-top: 4px;
  margin-bottom: 0px !important;
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.palette?.RED};
  display: block;
`;

export const ErrorInputContainer = styled.div<{ isInValid: boolean }>`
  .ant-input,
  .ant-input:focus {
    border-color: ${({ isInValid }) => isInValid && '#ff4d4f !important'};
  }
`;

export const ErrorInputContinerDynamic = styled.div<{
  isInvalid: boolean;
  target: string;
}>`
  ${({ target, isInvalid, theme }) => {
    return `
      .${target} {
        border-color: ${isInvalid ? `${theme.palette?.RED} !important` : ''};
      }
    `;
  }}
`;

export const ErrorMessage = styled.span`
  display: block;
  text-align: center;
  margin: 8px 0 16px;
  color: ${() => `${VALIDATE_TEXT_COLOR}`};
  font-weight: 400;
  font-size: 12px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette?.GREY_7};
  margin: 16px 0;
`;

export const HyperLinkFont14 = styled.a`
  font-size: 14px;
  font-weight: 400px;
  color: ${({ theme }) => theme.palette?.BLUE};
  cursor: pointer;
`;

export const Spacing = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
`;

export const FirstCapital = styled.span`
  text-transform: capitalize;
`;

export const KeywordCounter = styled.div`
  text-align: right;
`;

export const BreakWord = styled.div`
  word-break: break-word;
`;

export const TextEllipsis = styled.div<{ width: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: ${({ width }) => width}px;
`;

export const TextEllipsisClamp = styled.div<{ width: number; clamp?: number }>`
  max-width: ${({ width }) => width}px;

  p {
    display: -webkit-box;
    -webkit-line-clamp: ${({ clamp }) => clamp};
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 20px;
    word-break: break-word;
  }
`;

export const TextUnderline = styled.span`
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.palette?.GREY_1};
`;

export const TextCentered = styled.div`
  text-align: center;
`;

export const TransparentTertiaryButton = styled(TertiaryButton)`
  background-color: transparent;
`;

export const ButtonTrash = styled(Button)`
  background-color: rgba(0, 0, 0, 0.07);
`;

export const Frame = styled.div<{ width: number; height: number; centered?: boolean }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  ${({ centered }) =>
    centered &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export const ToastWrap = styled.div`
  margin-bottom: 8px;
  word-break: break-word;
  > div > div {
    display: flex;
    align-self: flex-start;
  }
`;

export const AbsCenteredX = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const AntdLoadingWrap = styled.div`
  width: 100%;
  height: 100%;
  .ant-spin-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .ant-spin-nested-loading {
    height: 100%;
  }
  .ant-spin.ant-spin-spinning {
    max-height: unset !important;
  }
`;

export const ValidateContainer = styled.div<{ isInvalidate: boolean; id?: string }>`
  .select-area {
    ${({ isInvalidate }) => (isInvalidate ? `border: 1px solid red !important;` : ``)}
  }
  ${({ isInvalidate, id }) =>
    id &&
    `
    #${id} {
      border-radius: 8px;
      ${isInvalidate ? `border: 1px solid red !important;` : ``}
    }
  `}
`;

export const ConfirmModalFullContent = styled(ConfirmModal)<{
  footerPosition?: 'flex-start' | 'center' | 'flex-end';
}>`
  .ant-modal-body > * {
    width: 100%;
  }
  .ant-modal-footer > div {
    display: flex;
    justify-content: ${({ footerPosition }) => `${footerPosition}`};
    button {
      width: fit-content;
    }
  }
`;

export const BgDot = styled.div`
  --dot-bg: #f5f5f5;
  --dot-color: #e9e9e9;
  --dot-size: 3px;
  --dot-space: 22px;
  background: linear-gradient(
        90deg,
        var(--dot-bg) calc(var(--dot-space) - var(--dot-size)),
        transparent 1%
      )
      center / var(--dot-space) var(--dot-space),
    linear-gradient(
        var(--dot-bg) calc(var(--dot-space) - var(--dot-size)),
        transparent 1%
      )
      center / var(--dot-space) var(--dot-space),
    var(--dot-color);
`;
