import { EFailAction } from '@configs';
import { IDropdownOption } from '@pages/bot_setting/machine';
import { ITemplateApi } from '@types';

export type INetworkSettingFE = {
  id: string;
  name: string;
  config: INetworkSettingConfigFE;
  createdAt?: string;
  updatedAt?: string;
  apiKey: string;
};

export type INetworkSettingConfigFE = {
  defaultFallbackAction: INetworkSettingDefaultFallbackAction;
  defaultLocationResponseAction?: INetworkSettingDefaultFallbackAction | null;
  confidenceThreshold: number;
  flowEngines: INetworkSettingFlowEngine[];
  hooks: INetworkSettingHooksFE;
  enabledFeatures: string[];
  passwordPolicy?: IPasswordPolicyFE;
  ssoSettings?: ISSOSettingBE[];
};

export type INetworkSettingDefaultFallbackAction = {
  action: string;
  metadata?: INetworkSettingMetadata;
};

export type INetworkSettingMetadata = {
  flag: string;
};

export type INetworkSettingFlowEngine = {
  flowEngineId: string;
  flowEngineEndpoint: string;
};

export type INetworkSettingHooksFE = {
  template?: {
    checked: boolean;
    value: string;
  };
  enrich?: {
    checked: boolean;
    value: string;
  };
  outgoing?: {
    checked: boolean;
    value: string;
  };
  classification: IClassifierFE;
  oldClassification?: string | null;
  thread?: string;
};

export type IClassifierFE = {
  mode: ECLASSIFIER_MODE;
  trainedAt?: string | null;
  trainedBy?: string | null;
  hash?: string | null;
  status?: ECLASSIFIER_STATUS | null;
  hook?: string | null;
  embeddingModel?: string | null;
};

// BE
export type INetworkSettingBE = {
  id: string;
  name: string;
  config: INetworkSettingConfigBE;
  createdAt?: string;
  updatedAt?: string;
  apiKey: string;
};

export type INetworkSettingConfigBE = {
  defaultFallbackAction: INetworkSettingDefaultFallbackAction;
  defaultLocationResponseAction?: INetworkSettingDefaultFallbackAction | null;
  confidenceThreshold: number;
  flowEngines: INetworkSettingFlowEngine[];
  hooks: INetworkSettingHooksBE;
  classifier: IClassifierBE;
  enabledFeatures: string[];
  passwordPolicy?: IPasswordPolicyBE;
  authenticationPolicy?: IAuthPolicyBE;
  ssoSettings?: ISSOSettingBE[];
};

export const enum ISSOTypeBE {
  SAML2 = 'SAML2',
  OIDC = 'OIDC',
}

export type ISSOCustomAttributesBE = {
  domain: string;
};

export type ISSOSettingBE = {
  type: ISSOTypeBE;
  organizationId: string;
  providerName: string;
  enabled: boolean;
  roleMap: Record<string, string[]>;
  customAttributes: ISSOCustomAttributesBE;
};
export type INetworkSettingHooksBE = {
  template?: string | null;
  enrich?: string | null;
  outgoing?: string | null;
  classification?: string | null;
  thread?: string | null;
};

export type INetworkSettingRequest = {
  networkId: string;
  name: string;
  config: INetworkSettingConfigBE;
  createdAt?: string;
  updatedAt?: string;
  apiKey: string;
};

export const enum ECLASSIFIER_MODE {
  VECTOR = 'vector',
  TEXT = 'text',
  CUSTOM = 'custom',
}

export const enum ECLASSIFIER_STATUS {
  CHANGED = 'changed',
  TRAINING = 'training',
  TRAINED = 'trained',
}

export type IClassifierBE = {
  mode: ECLASSIFIER_MODE;
  trainedAt?: string | null;
  trainedBy?: string | null;
  hash?: string | null;
  status?: ECLASSIFIER_STATUS | null;
  hook?: string | null;
  embeddingModel?: string | null;
};

// event socket
export const enum ENETWORK_SETTING_SOCKET_EVENT {
  UPDATED = 'network.updated',
}
export type INetworkSettingSocketEvent = {
  event: ENETWORK_SETTING_SOCKET_EVENT;
  json: INetworkSettingBE;
};

export type ILocationDefaultActionFE = {
  isActive: boolean;
  selectedFailedAction: EFailAction | null;
  selectedTemplate: ITemplateApi | null;
  selectedFlowEngine: IDropdownOption | null;
  selectedFlow: IDropdownOption | null;
};

// password policy FE
export type IPasswordPolicyFE = {
  superAdminPassword: {
    isEnabled: boolean;
    min: number;
    max: number;
  };
  passwordChange: {
    isEnabled: boolean;
    attemptLimit: number;
    timeSpan: number;
  };
  duplicatedPasswordPrevention: {
    isEnabled: boolean;
    retentionLimit: number;
  };
  forceChangePassword: {
    isEnabled: boolean;
    superAdmin: number;
    normalUser: number;
  };
};

// password policy BE
export type IPasswordPolicyBE = {
  changeFrequency: IChangeFrequencyBE;
  complexity: IComplexityBE;
  reusePrevention: IReusePreventionBE;
  forceChangePassword: IForceChangePasswordBE;
};

export type IForceChangePasswordBE = {
  isEnabled: boolean;
  roles: IForceChangePasswordRoleBE[];
};

export type IForceChangePasswordRoleBE = {
  role: string;
  valid: number;
};

export type IChangeFrequencyBE = {
  attemptLimit: number;
  timeSpan: number;
  isEnabled: boolean;
};

export type IComplexityBE = {
  roles: IPasswordPolicyRoleBE[];
  isEnabled: boolean;
};

export type IPasswordPolicyRoleBE = {
  role: string;
  min: number;
  max: number;
};

export type IReusePreventionBE = {
  retentionLimit: number;
  isEnabled: boolean;
};

// Auth policy FE
export type IAuthPolicyFE = {
  accountLockout: {
    isEnabled: boolean;
    failedAttemptLimit: number;
    interval: number;
    lockoutPeriods: number;
  };
  autoDisableUser: {
    isEnabled: boolean;
    threshold: number;
  };
};

// Auth policy BE
export type IAccountLockoutBE = {
  failedAttemptLimit: number;
  interval: number; // fixed to 5 mintues
  lockoutPeriods: number; // Minutes
  isEnabled: boolean;
};
export type IAutoDisableUserBE = {
  threshold: number; // Days
  isEnabled: boolean;
};

export type IAuthPolicyBE = {
  autoDisableUser: IAutoDisableUserBE;
  accountLockout: IAccountLockoutBE;
};
